import { BrowserRouter, Routes, Route } from 'react-router-dom'

import NavigationTabs from './components/NavigationsTabs';
import ListOfMerchants from './pages/ListOfMerchants';
import ListOfCardholders from './pages/ListOfCardholders';
import MerchantDetails from './pages/MerchantDetails';
import CardholderDetails from './pages/CardholderDetails';
import OfferDetails from './pages/OfferDetails';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <NavigationTabs />
          <Routes>
            <Route
              path='/merchants'
              element={<ListOfMerchants />}
            />
            <Route
              path='/cardholders'
              element={<ListOfCardholders />}
            />
            <Route
              path='/merchants/:id'
              element={<MerchantDetails />}
            />
            <Route
              path='/cardholders/:id'
              element={<CardholderDetails />}
            />
            <Route
              path='/offers/:id'
              element={<OfferDetails />}
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
