// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer-details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    margin: 60px 20px;
}

.offer-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 70%;
}

.detail-item {
    display: flex;
    align-items: center;
}

.offer-image {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.detail-item label {
    font-weight: bold;
    margin-right: 10px;
}

.coupon-grid {
    width: 1100px;
    height: 400px;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/OfferDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,cAAc;AAClB","sourcesContent":[".offer-details-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    padding: 20px;\n    margin: 60px 20px;\n}\n\n.offer-details {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n    width: 70%;\n}\n\n.detail-item {\n    display: flex;\n    align-items: center;\n}\n\n.offer-image {\n    width: 25%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.offer-image img {\n    max-width: 100%;\n    height: auto;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n}\n\n.detail-item label {\n    font-weight: bold;\n    margin-right: 10px;\n}\n\n.coupon-grid {\n    width: 1100px;\n    height: 400px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
