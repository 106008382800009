import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from 'react-bootstrap';
import NewLocationModal from '../components/NewLocationModal';

const LocationsTable = () => {
    const [showModal, setShowModal] = useState(false);

    // Hardcoded locations data
    const locations = [
        { id: 1, name: "Location A", address: "123 Main St, City, Country", loginToken: "token123" },
        { id: 2, name: "Location B", address: "456 Elm St, City, Country", loginToken: "token456" },
        { id: 3, name: "Location C", address: "789 Pine St, City, Country", loginToken: "token789" },
        { id: 4, name: "Location D", address: "321 Oak St, City, Country", loginToken: "token321" },
        { id: 5, name: "Location E", address: "654 Maple St, City, Country", loginToken: "token654" },
    ];

    // Function to copy text to clipboard
    const copyToClipboard = (token) => {
        navigator.clipboard.writeText(token).then(() => {
            alert("Token copied to clipboard!");
        }).catch(err => {
            console.error("Failed to copy: ", err);
        });
    };

    const columns = [
        { headerName: 'Location ID', field: 'id' },
        { headerName: 'Name', field: 'name' },
        { headerName: 'Address', field: 'address' },
        {
            headerName: 'Login Token',
            field: 'loginToken',
            cellRenderer: (params) => (
                <>
                    <span>{params.value}</span>
                    <button
                        onClick={() => copyToClipboard(params.value)}
                        style={{ marginLeft: '10px' }}
                    >
                        Copy
                    </button>
                </>
            )
        }
    ];

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className='locations'>
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    rowData={locations}
                    columnDefs={columns}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            <NewLocationModal show={showModal} handleClose={handleCloseModal} />
            <div className="new-offer-button">
                <Button variant="primary" onClick={handleShowModal}>
                    New Location
                </Button>
            </div>
        </div>
    );
};

export default LocationsTable;
