// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardholder-details {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 20px;
    margin: 50px 30px;
}

.cardholder-info {
    flex: 1 1;
    display: grid;
    grid-template-columns: 30% 30%;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

}

h2 {
    grid-column: 1 / -1;
    /* Naslov da zauzima obe kolone */
    text-align: left;
    /* Centriraj ga levo */
    margin-bottom: 20px;
}

.info-item {
    display: flex;
    justify-content: flex-start;
    /* Labele na levoj strani */
    align-items: center;
    /* Vertikalno poravnanje */
    margin-right: 10px;
    /* Suzbijanje divova na levo */
}

.info-item label {
    font-weight: bold;
    margin-right: 10px;
}

.ag-theme-alpine {
    margin-left: 30px;
    margin-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/styles/CardholderDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;IACI,mBAAmB;IACnB,iCAAiC;IACjC,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,2BAA2B;IAC3B,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".cardholder-details {\n    display: flex;\n    justify-content: space-between;\n    align-items: stretch;\n    padding-top: 20px;\n    margin: 50px 30px;\n}\n\n.cardholder-info {\n    flex: 1;\n    display: grid;\n    grid-template-columns: 30% 30%;\n    grid-gap: 10px;\n    align-items: center;\n    padding: 10px;\n\n}\n\nh2 {\n    grid-column: 1 / -1;\n    /* Naslov da zauzima obe kolone */\n    text-align: left;\n    /* Centriraj ga levo */\n    margin-bottom: 20px;\n}\n\n.info-item {\n    display: flex;\n    justify-content: flex-start;\n    /* Labele na levoj strani */\n    align-items: center;\n    /* Vertikalno poravnanje */\n    margin-right: 10px;\n    /* Suzbijanje divova na levo */\n}\n\n.info-item label {\n    font-weight: bold;\n    margin-right: 10px;\n}\n\n.ag-theme-alpine {\n    margin-left: 30px;\n    margin-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
