import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

function NewMerchantModal({ show, onHide }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        reset(); // Resetovanje forme
        onHide(); // Zatvaranje modala nakon uspešnog unosa
        // Ovde dodaj logiku za slanje podataka na server
    };

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Merchant
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="companyName" className="form-label">Company Name</label>
                        <input
                            id="companyName"
                            className="form-control"
                            {...register('companyName', { required: true })}
                        />
                        {errors.companyName && <span className="text-danger">This field is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="category" className="form-label">Category</label>
                        <input
                            id="category"
                            className="form-control"
                            {...register('category', { required: true })}
                        />
                        {errors.category && <span className="text-danger">This field is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            id="description"
                            className="form-control"
                            {...register('description', { required: true })}
                        />
                        {errors.description && <span className="text-danger">This field is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="logo" className="form-label">Upload Logo</label>
                        <input
                            id="logo"
                            type="file"
                            className="form-control"
                            {...register('logo', { required: true })}
                        />
                        {errors.logo && <span className="text-danger">This field is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="heroImage" className="form-label">Upload Hero Image</label>
                        <input
                            id="heroImage"
                            type="file"
                            className="form-control"
                            {...register('heroImage', { required: true })}
                        />
                        {errors.heroImage && <span className="text-danger">This field is required</span>}
                    </div>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default NewMerchantModal;
