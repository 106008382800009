// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchants-page {
    padding-top: 24px;
    display: flex;
}

.sidebar {
    display: flex;
    flex-direction: column;
    /* Rasporedi elemente vertikalno */
    position: sticky;
    top: 0;
    width: 200px;
    height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    top: 60px;
}

.sidebar h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    flex-grow: 1;
    /* Omogućava listi da raste i zauzme prostor */
}

.sidebar li {
    padding: 10px 0;
    cursor: pointer;
    font-size: 1rem;
}

.sidebar li.active {
    font-weight: bold;
    color: #007bff;
}

.add-merchant-button {
    position: fixed;
    bottom: 20px;
    /* Od dna ekrana */
    left: 20px;
    /* Od leve strane ekrana */
    z-index: 1000;
    /* Osigurava da dugme bude iznad drugih elemenata */
}


.merchant-list {
    flex-grow: 1;
    padding: 20px;
}

.category-section {
    padding-top: 50px;
    margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ListOfMerchants.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kCAAkC;IAClC,gBAAgB;IAChB,MAAM;IACN,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,8CAA8C;AAClD;;AAEA;IACI,eAAe;IACf,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,0BAA0B;IAC1B,aAAa;IACb,mDAAmD;AACvD;;;AAGA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".merchants-page {\n    padding-top: 24px;\n    display: flex;\n}\n\n.sidebar {\n    display: flex;\n    flex-direction: column;\n    /* Rasporedi elemente vertikalno */\n    position: sticky;\n    top: 0;\n    width: 200px;\n    height: 100vh;\n    background-color: #f8f9fa;\n    padding: 20px;\n    top: 60px;\n}\n\n.sidebar h3 {\n    font-size: 1.2rem;\n    margin-bottom: 1rem;\n}\n\n.sidebar ul {\n    list-style: none;\n    padding: 0;\n    flex-grow: 1;\n    /* Omogućava listi da raste i zauzme prostor */\n}\n\n.sidebar li {\n    padding: 10px 0;\n    cursor: pointer;\n    font-size: 1rem;\n}\n\n.sidebar li.active {\n    font-weight: bold;\n    color: #007bff;\n}\n\n.add-merchant-button {\n    position: fixed;\n    bottom: 20px;\n    /* Od dna ekrana */\n    left: 20px;\n    /* Od leve strane ekrana */\n    z-index: 1000;\n    /* Osigurava da dugme bude iznad drugih elemenata */\n}\n\n\n.merchant-list {\n    flex-grow: 1;\n    padding: 20px;\n}\n\n.category-section {\n    padding-top: 50px;\n    margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
