import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import NewOfferModal from '../components/NewOfferModal';

const OffersTable = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const offers = [
        {
            id: 1,
            title: "Discount on Electronics",
            validFrom: "2024-09-01",
            validTo: "2024-09-30",
            totalCoupons: 100,
            claimedCoupons: 50,
            redeemedCoupons: 30 // Broj iskorišćenih kupona
        },
        {
            id: 2,
            title: "Buy One Get One Free on Clothing",
            validFrom: "2024-09-05",
            validTo: "2024-10-05",
            totalCoupons: 200,
            claimedCoupons: 75,
            redeemedCoupons: 50 // Broj iskorišćenih kupona
        },
        {
            id: 3,
            title: "20% Off on Home Appliances",
            validFrom: "2024-09-10",
            validTo: "2024-09-25",
            totalCoupons: 150,
            claimedCoupons: 100,
            redeemedCoupons: 70 // Broj iskorišćenih kupona
        },
        {
            id: 4,
            title: "Free Shipping on Orders Over $50",
            validFrom: "2024-09-12",
            validTo: "2024-10-12",
            totalCoupons: 300,
            claimedCoupons: 90,
            redeemedCoupons: 20 // Broj iskorišćenih kupona
        },
        {
            id: 5,
            title: "10% Cashback on Grocery Purchases",
            validFrom: "2024-09-15",
            validTo: "2024-09-30",
            totalCoupons: 250,
            claimedCoupons: 40,
            redeemedCoupons: 10 // Broj iskorišćenih kupona
        }
    ];

    const columns = [
        { headerName: 'Offer ID', field: 'id' },
        { headerName: 'Title', field: 'title' },
        { headerName: 'Valid From', field: 'validFrom' },
        { headerName: 'Valid To', field: 'validTo' },
        { headerName: 'Total Coupons', field: 'totalCoupons' },
        { headerName: 'Claimed Coupons', field: 'claimedCoupons' },
        { headerName: 'Redeemed Coupons', field: 'redeemedCoupons' }, // Prikaz broja iskorišćenih kupona
        {
            headerName: '',
            cellRenderer: (params) => (
                <button
                    onClick={() => navigate(`/offers/${params.data.id}`)}
                    style={{ marginLeft: '10px' }}
                >
                    View
                </button>
            )
        }
    ];

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className='offers'>
            <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                    rowData={offers}
                    columnDefs={columns}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            <NewOfferModal show={showModal} handleClose={handleCloseModal} />
            <div className="new-offer-button">
                <Button variant="primary" onClick={handleShowModal}>
                    New Offer
                </Button>
            </div>
        </div>
    );
};

export default OffersTable;
