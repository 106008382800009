import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const OfferDetailsTable = () => {


    const coupons = [
        {
            couponCode: "COUPON123",
            dateClaimed: "2024-09-01",
            claimedBy: "john.doe@example.com",
            dateRedeemed: "2024-09-10",
            locationRedeemed: "Location A"
        },
        {
            couponCode: "COUPON456",
            dateClaimed: "2024-09-02",
            claimedBy: "jane.smith@example.com",
            dateRedeemed: null, // Kupon nije iskorišćen
            locationRedeemed: null // Kupon nije iskorišćen
        },
        {
            couponCode: "COUPON789",
            dateClaimed: "2024-09-03",
            claimedBy: "alex.jones@example.com",
            dateRedeemed: "2024-09-15",
            locationRedeemed: "Location B"
        },
        {
            couponCode: "COUPON101",
            dateClaimed: "2024-09-05",
            claimedBy: "maria.garcia@example.com",
            dateRedeemed: null, // Kupon nije iskorišćen
            locationRedeemed: null // Kupon nije iskorišćen
        },
        {
            couponCode: "COUPON202",
            dateClaimed: "2024-09-10",
            claimedBy: "david.brown@example.com",
            dateRedeemed: "2024-09-20",
            locationRedeemed: "Location C"
        }
    ];


    const columnDefs = [
        { headerName: 'Coupon Code', field: 'couponCode' },
        { headerName: 'Date Claimed', field: 'dateClaimed' },
        { headerName: 'Claimed By', field: 'claimedBy' },
        { headerName: 'Date Redeemed', field: 'dateRedeemed' },
        { headerName: 'Location Redeemed', field: 'locationRedeemed' }
    ];


    return (
        <div className="ag-theme-alpine coupon-grid" style={{ height: 400 }}>
            <AgGridReact
                rowData={coupons}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={10}
            />
        </div>
    );
}

export default OfferDetailsTable;