import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import '../styles/ListOfMerchants.css'
import NewMerchantModal from '../components/NewMerchantModal';

const MerchantsList = () => {
    const [merchants, setMerchants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMerchants = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setMerchants(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchMerchants();
    }, []);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const groupedMerchants = merchants.reduce((acc, merchant) => {
        if (!acc[merchant.category]) {
            acc[merchant.category] = [];
        }
        acc[merchant.category].push(merchant);
        return acc;
    }, {});

    const categories = Object.keys(groupedMerchants);

    return (
        <div className="merchants-page">

            <Sidebar categories={categories} />

            <Container className="merchant-list">
                {categories.map((category) => (
                    <div key={category} id={category} className="category-section">
                        <h2>{category}</h2>
                        <Row className="g-4 mb-4">
                            {groupedMerchants[category].map((merchant) => (
                                <Col key={merchant.id} xs={12} md={6} lg={4} className="d-flex justify-content-center">
                                    <Card style={{ width: '100%', cursor: 'pointer' }} onClick={() => navigate(`/merchants/${merchant.id}`)}>
                                        <Card.Body className="d-flex align-items-start">
                                            <img src={merchant.logo} alt={merchant.companyName} className="me-3" style={{ width: '50px', height: '50px' }} />
                                            <div>
                                                <Card.Title className="fw-bold mb-1">{merchant.companyName}</Card.Title>
                                                <Card.Text style={{ fontSize: '0.7rem' }}>
                                                    {merchant.description}
                                                </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </Container>
            <Button
                variant="primary"
                className="add-merchant-button" // Fiksira dugme na dnu
                onClick={() => setModalShow(true)}
            >
                New Merchant
            </Button>
            <NewMerchantModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
};

export default MerchantsList;