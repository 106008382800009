import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';

function NavigationTabs() {
    const location = useLocation()

    const navStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000, // Ensures it stays above other content
    };

    return (
        <
            Nav className="bg-light p-3"
            variant="underline"
            defaultActiveKey={location.pathname}
            style={navStyle}
        >
            <Nav.Item>
                <Nav.Link href="/merchants">Merchants</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/cardholders">Cardholders</Nav.Link>
            </Nav.Item>
        </Nav>
    );
}

export default NavigationTabs;