import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

function NewLocationModal({ show, handleClose }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        reset(); // Resetovanje forme
        handleClose(); // Zatvaranje modala nakon uspešnog unosa
        // Ovde dodaj logiku za slanje podataka na server
    };

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Location
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="locationName" className="form-label">Location Name</label>
                        <input
                            id="locationName"
                            className="form-control"
                            {...register('locationName', { required: true })}
                        />
                        {errors.locationName && <span className="text-danger">This field is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input
                            id="address"
                            className="form-control"
                            {...register('address', { required: true })}
                        />
                        {errors.address && <span className="text-danger">This field is required</span>}
                    </div>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default NewLocationModal;
