import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const CardholderDetailsTable = () => {


    const vouchers = [
        { id: '001', merchant: 'Store A', offertitle: 'Discount 10%', dateclaimed: '2023-09-01', dateredeemed: '2023-09-10', locationredeemed: 'Online' },
        { id: '002', merchant: 'Store B', offertitle: 'Free Shipping', dateclaimed: '2023-08-15', dateredeemed: '2023-08-20', locationredeemed: 'In-store' },
        { id: '003', merchant: 'Store C', offertitle: 'Buy 1 Get 1', dateclaimed: '2023-07-20', dateredeemed: '2023-07-25', locationredeemed: 'Online' },
        { id: '004', merchant: 'Store D', offertitle: '15% off', dateclaimed: '2023-06-10', dateredeemed: '2023-06-15', locationredeemed: 'In-store' },
        { id: '005', merchant: 'Store E', offertitle: 'Exclusive Offer', dateclaimed: '2023-05-05', dateredeemed: '2023-05-10', locationredeemed: 'Online' }
    ];

    const columns = [
        { headerName: 'Coupon ID', field: 'id' },
        { headerName: 'Merchant', field: 'merchant' },
        { headerName: 'Offer title', field: 'offertitle' },
        { headerName: 'Date Claimed', field: 'dateclaimed' },
        { headerName: 'Date Redeemed', field: 'dateredeemed' },
        { headerName: 'Location Redeemed', field: 'locationredeemed' }
    ];


    return (
        <div className="ag-theme-alpine" style={{ height: 400 }}>
            <AgGridReact
                rowData={vouchers}
                columnDefs={columns}
                pagination={true}
                paginationPageSize={10}
            />
        </div>
    );
}

export default CardholderDetailsTable;