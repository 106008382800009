import React, { useState, useEffect } from 'react';


const Sidebar = ({ categories, onAddMerchant }) => {
    const [activeCategory, setActiveCategory] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const categoryElements = categories.map(category => document.getElementById(category));
            for (const el of categoryElements) {
                const rect = el.getBoundingClientRect();
                if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
                    setActiveCategory(el.id);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [categories]);

    return (
        <div className="sidebar">

            <ul>
                {categories.map((category) => (
                    <li
                        key={category}
                        className={activeCategory === category ? 'active' : ''}
                        onClick={() => document.getElementById(category).scrollIntoView({ behavior: 'smooth' })}
                    >
                        {category}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
