import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import OfferDetailsTable from '../components/OfferDetailsTable';

import '../styles/OfferDetails.css';

const OfferDetails = () => {
    const { id } = useParams();
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        const fetchOfferDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/offers/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setOffer(data);
            } catch (error) {
                console.error('Failed to fetch offer details:', error);
            }
        };



        fetchOfferDetails();
    }, [id]);

    if (!offer) return <div>Loading...</div>;



    return (
        <>
            <div className="offer-details-container">
                <div className="offer-details">
                    <h2>Offer Details</h2>
                    <div className="detail-item">
                        <label>Offer ID: </label>
                        <span>{offer.id}</span>
                    </div>
                    <div className="detail-item">
                        <label>Valid From: </label>
                        <span>{offer.validFrom}</span>
                    </div>
                    <div className="detail-item">
                        <label>Coupons Generated: </label>
                        <span>{offer.couponsGenerated}</span>
                    </div>
                    <div className="detail-item">
                        <label>Title: </label>
                        <span>{offer.title}</span>
                    </div>
                    <div className="detail-item">
                        <label>Valid To: </label>
                        <span>{offer.validTo}</span>
                    </div>
                    <div className="detail-item">
                        <label>Coupons Redeemed: </label>
                        <span>{offer.couponsRedeemed}</span>
                    </div>
                    <div className="detail-item">
                        <label>Merchant Name: </label>
                        <span>{offer.merchantName}</span>
                    </div>
                    <div className="detail-item">
                        <label>Days Left: </label>
                        <span>{offer.daysLeft}</span>
                    </div>
                    <div className="detail-item">
                        <label>Percentage Redeemed: </label>
                        <span>{offer.percentageRedeemed}%</span>
                    </div>
                </div>

                <div className="offer-image">
                    <img src={offer.imageUrl} alt={offer.title} />
                </div>
            </div>
            <OfferDetailsTable />
        </>
    );
};

export default OfferDetails;
