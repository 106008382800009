import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const NewOfferModal = ({ show, handleClose }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log('New Offer:', data);
        // Poziv za slanje podataka na server može biti ovde
        handleClose(); // Zatvara modal nakon slanja podataka
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>New Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formOfferTitle">
                                <Form.Label>Offer Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer title"
                                    {...register('offerTitle', { required: 'Offer title is required' })}
                                />
                                {errors.offerTitle && <span className="text-danger">{errors.offerTitle.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formOfferType" className="mt-3">
                                <Form.Label>Offer Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer type"
                                    {...register('offerType', { required: 'Offer type is required' })}
                                />
                                {errors.offerType && <span className="text-danger">{errors.offerType.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formOfferDescription" className="mt-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter offer description"
                                    {...register('offerDescription', { required: 'Description is required' })}
                                />
                                {errors.offerDescription && <span className="text-danger">{errors.offerDescription.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formLongDescription" className="mt-3">
                                <Form.Label>Long Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter long description"
                                    {...register('longDescription', { required: 'Long description is required' })}
                                />
                                {errors.longDescription && <span className="text-danger">{errors.longDescription.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formImage" className="mt-3">
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    {...register('image')}
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="formValidFrom">
                                <Form.Label>Valid From</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validFrom', { required: 'Valid from date is required' })}
                                />
                                {errors.validFrom && <span className="text-danger">{errors.validFrom.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formValidTo" className="mt-3">
                                <Form.Label>Valid To</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validTo', { required: 'Valid to date is required' })}
                                />
                                {errors.validTo && <span className="text-danger">{errors.validTo.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formDiscount" className="mt-3">
                                <Form.Label>Discount (%)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter discount"
                                    {...register('discount', { required: 'Discount is required' })}
                                />
                                {errors.discount && <span className="text-danger">{errors.discount.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="formNumCoupons" className="mt-3">
                                <Form.Label>Number of Coupons</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter number of coupons"
                                    {...register('numCoupons', { required: 'Number of coupons is required' })}
                                />
                                {errors.numCoupons && <span className="text-danger">{errors.numCoupons.message}</span>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="text-end mt-4">
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewOfferModal;
